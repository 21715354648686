import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['trigger'];

  connect() {
    switch (this.data.get('visibility')) {
      case 'always':
        this.showModal();
        break;
      case 'once':
        this.showModalOnce();
        break;
      case 'once_per_day':
        this.showModalOncePerDay();
        break;
    }
  }

  showModal() {
    this.triggerTarget.click();
  }

  showModalOnce() {
    if (this.getLocalStorageItem() === null) {
      this.showModal();
      this.updateLocalStorageItem();
    }
  }

  showModalOncePerDay() {
    if (this.getLocalStorageItem() === null || this.getLocalStorageItem() !== this.formattedDate) {
      this.showModal();
      this.updateLocalStorageItem();
    }
  }

  get formattedDate() {
    return new Date().toISOString().split('T')[0];
  }

  getLocalStorageItem() {
    return localStorage.getItem(this.data.get('key'));
  }

  updateLocalStorageItem() {
    localStorage.setItem(this.data.get('key'), this.formattedDate);
  }
}
