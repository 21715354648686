import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

/**
 * Auto submits a form when the #submit action is triggered
 * Use data-autosubmit-turbo="true" for turbo
 *
 * @example
 * %form(data-controller="autosubmit" data-autosubmit-turbo="true")
 *   %input(type="checkbox" data-action="change->autosubmit#submit")
 */
export default class extends Controller {
  submit() {
    if (this.data.has('turbo')) {
      this.element.dispatchEvent(new CustomEvent('submit', { bubbles: true, cancelable: true }));
    } else {
      $(this.element).trigger('submit');
    }
  }

  // Debounced version of the update function which prevent multiple requests from being sent in quick succession.
  // Usage: data-action="change->autosubmit#debouncedSubmit"
  debouncedSubmit = debounce(this.submit, 250);
}
