import { Controller } from '@hotwired/stimulus';

/**
 * Expands or collapses an area with an animation via a trigger (such as a button)
 *
 * @example
 * %div(data-controller="expandable")
 *   %div(data-expandable-target="area" style="display: none")
 *     Content goes here...
 *   %button(type="button" data-expandable-target="expand") Expand
 *   %button(type="button" data-expandable-target="collapse" style="display: none") Collapse
 */
export default class extends Controller {
  static targets = ['area', 'expand', 'collapse'];

  connect() {
    $(this.expandTarget).on('click', this.handleExpandClick);
    $(this.collapseTarget).on('click', this.handleCollapseClick);
  }

  disconnect() {
    $(this.expandTarget).off('click', this.handleExpandClick);
    $(this.collapseTarget).off('click', this.handleCollapseClick);
  }

  handleExpandClick = () => {
    $(this.areaTarget).slideDown('fast');
    $(this.expandTarget).hide();
    $(this.collapseTarget).show();
  };

  handleCollapseClick = () => {
    $(this.areaTarget).slideUp('fast');
    $(this.expandTarget).show();
    $(this.collapseTarget).hide();
  };
}
