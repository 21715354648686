import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['close', 'form'];

  connect() {
    this.closeTargets.forEach((target) => target.addEventListener('click', this.handleCloseClick));
    $(this.formTargets).on('ajax:complete', this.handleFormAjaxSuccess);
    this.element.addEventListener('turbo:submit-end', this.handleTurboSubmitEnd);
    this.element.style.animationName = this.data.get('animation');
  }

  disconnect() {
    this.closeTargets.forEach((target) => target.removeEventListener('click', this.handleCloseClick));
    $(this.formTargets).off('ajax:complete', this.handleFormAjaxSuccess);
    this.element.removeEventListener('turbo:submit-end', this.handleTurboSubmitEnd);
  }

  handleCloseClick = () => {
    this.element.dispatchEvent(new CustomEvent('dialog.close'));
  };

  handleFormAjaxSuccess = (e, xhr) => {
    if (xhr.status === 201) {
      // Keep form elements disabled while redirecting
      setTimeout($.rails.disableFormElements, 1, $(this.formTargets));
    }

    if (xhr.status === 202) {
      this.element.dispatchEvent(new CustomEvent('dialog.close'));
    }
  };

  handleTurboSubmitEnd = () => {
    $.rails.enableFormElements($(this.element));
  };

  get hasUnsavedChanges() {
    if (!this.hasFormTarget) {
      return false;
    }

    return this.application.getControllerForElementAndIdentifier(this.formTarget, 'changeset')?.hasUnsavedChanges;
  }
}
