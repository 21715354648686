const wafDetected = () => {
  document.cookie = 'waf_detected_at=' + btoa(window.location.pathname + window.location.search) + '; path=/';
  window.location.href = '/human-verification';
};

const originalXhr = window.XMLHttpRequest;
window.XMLHttpRequest = function () {
  const xhr = new originalXhr();

  const originalOpen = xhr.open;

  xhr.open = function () {
    const originalOnreadystatechange = xhr.onreadystatechange;
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if ([202, 405].includes(xhr.status) && xhr.responseText.includes('Human Verification')) {
          wafDetected();
        }
      }
      if(originalOnreadystatechange){
      	originalOnreadystatechange.apply(this, arguments)      
      }
    };
    originalOpen.apply(this, arguments);
  };

  return xhr;
};

const originalFetch = window.fetch;
window.fetch = async (resource, options) => {
  const response = await originalFetch(resource, options);
  if ([202, 405].includes(response.status) && (await response.clone().text()).includes('Human Verification')) {
    wafDetected();
    return Promise.reject();
  }
  return response;
};
