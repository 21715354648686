import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['stuck'];
  static targets = ['sticky', 'offset'];

  connect() {
    this.boundToggleStuck = this.toggleStuck.bind(this);
    document.addEventListener('scroll', this.boundToggleStuck);

    this.boundSetOffset = this.setOffset.bind(this);
    window.addEventListener('resize', this.boundSetOffset);

    setTimeout(() => this.setOffset(), 10); // Small delay allows offsetTarget size to stabilize on load
    this.toggleStuck();
  }

  disconnect() {
    document.removeEventListener('scroll', this.boundToggleStuck);
    window.removeEventListener('resize', this.boundSetOffset);
  }

  setOffset() {
    if (this.hasOffsetTarget) {
      this.offset = this.offsetTarget.getBoundingClientRect().bottom;
      this.stickyTarget.style.top = this.offset + 'px';
    } else {
      this.offset = 0;
    }
  }

  toggleStuck() {
    // Position fixed means a modal is open
    if (document.body.style.position === 'fixed') return;

    const stuck =
      getComputedStyle(this.stickyTarget).position == 'sticky' &&
      this.stickyTarget.getBoundingClientRect().top <= this.offset + 1 && // <= and +1 prevents flicker
      window.scrollY > 0; // Must not be scrolled to top of page (a nav bar without a top bar starts at stuck position)

    if (this.stuck != stuck) {
      this.element.classList.toggle(this.stuckClass, stuck);
      this.stuck = stuck;
      window.dispatchEvent(new CustomEvent('toggle-stuck'));
    }
  }
}
