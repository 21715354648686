import { Controller } from '@hotwired/stimulus';

// Turbo redirecting causes cors issues for redirecting to external urls with turbo frames/streams.
// We use this simple controller to bypass the turbo redirection
// https://github.com/hotwired/turbo/issues/401#issuecomment-1032492888

export default class extends Controller {
  static values = { url: String };

  connect() {
    window.location.href = this.urlValue;
  }
}
