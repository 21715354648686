import c0 from './auto_submit_period_select_controller';
import c1 from './consent/consent_management_controller';
import c2 from './initialize_controller';
import c3 from './openable_controller';
import c4 from './overlappable_controller';
import c5 from './period_select_controller';
import c6 from './pop_up_controller';
import c7 from './recaptcha_controller';
import c8 from './redirect_controller';
import c9 from './slideshow_controller';
import c10 from './sticky_controller';
export const definitions = [
	{identifier: 'auto-submit-period-select', controllerConstructor: c0},
	{identifier: 'consent--consent-management', controllerConstructor: c1},
	{identifier: 'initialize', controllerConstructor: c2},
	{identifier: 'openable', controllerConstructor: c3},
	{identifier: 'overlappable', controllerConstructor: c4},
	{identifier: 'period-select', controllerConstructor: c5},
	{identifier: 'pop-up', controllerConstructor: c6},
	{identifier: 'recaptcha', controllerConstructor: c7},
	{identifier: 'redirect', controllerConstructor: c8},
	{identifier: 'slideshow', controllerConstructor: c9},
	{identifier: 'sticky', controllerConstructor: c10},
];
