document.addEventListener('turbo:before-stream-render', function (e) {
  const turboStream = e.target;

  if (turboStream.action === 'push_data_layer') {
    e.preventDefault();

    const dataLayerEvent = JSON.parse(turboStream.templateContent.textContent);
    pushDataLayer(dataLayerEvent);
  }
});

document.addEventListener('turbo:load', function (e) {
  const gtmDataLayer = document.getElementById('data-layer');
  if (gtmDataLayer) {
    const dataLayerEvents = JSON.parse(gtmDataLayer.textContent);
    dataLayerEvents.forEach(pushDataLayer);
  }
});

function pushDataLayer(dataLayerEvent) {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({ ecommerce: null });
  dataLayer.push(dataLayerEvent);
}
