import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['widget'];
  static values = {
    siteKey: String,
    action: String,
  };

  handleSubmit(event) {
    event.preventDefault();

    grecaptcha.enterprise.ready(() => {
      grecaptcha.enterprise.execute(this.siteKeyValue, { action: this.actionValue }).then((token) => {
        this.widgetTarget.value = token;
        Turbo.navigator.submitForm(this.element);
      });
    });
  }
}
