import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('period-select:change', this.handlePeriodSelectChange);
  }

  disconnect() {
    this.element.removeEventListener('period-select:change', this.handlePeriodSelectChange);
  }

  handlePeriodSelectChange = () => {
    $(this.element).trigger('filter.parkcms');
  };
}
