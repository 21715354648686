import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['open'];
  static targets = ['dialog', 'inside', 'hide', 'show', 'focus'];
  static values = { manageScroll: Boolean, enableHover: Boolean, closeOutside: Boolean, initialOpen: Boolean };

  // Lifecycle

  connect() {
    this.isOpen = false;
    this.showTargets.forEach((element) => (element.style.display = 'none'));
    this.enableHover = window.matchMedia('(hover: hover) and (pointer: fine)').matches && this.enableHoverValue;

    this.isOpen = this.openValue;

    this.toggleOpenClass();
    this.hideAndShowTarget();

    if (this.isOpen) this.disableScroll();

    this.boundCloseOnEscape = this.closeOnEscape.bind(this);
    document.addEventListener('keydown', this.boundCloseOnEscape);

    if (this.closeOutsideValue) {
      this.boundCloseIfOutside = this.closeIfOutside.bind(this);
      document.addEventListener('click', this.boundCloseIfOutside);
    }

    if (this.enableHover) {
      this.boundOpenOnHover = this.openOnHover.bind(this);
      this.element.addEventListener('mouseenter', this.boundOpenOnHover);

      this.boundCloseOnHover = this.closeOnHover.bind(this);
      this.element.addEventListener('mouseleave', this.boundCloseOnHover);
    }

    if (this.initialOpenValue) {
      this.open();
    }
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundCloseOnEscape);

    if (this.closeOutsideValue) {
      document.removeEventListener('click', this.boundCloseIfOutside);
    }

    if (this.enableHover) {
      this.element.removeEventListener('mouseenter', this.boundOpenOnHover);
      this.element.removeEventListener('mouseleave', this.boundCloseOnHover);
    }
  }

  // Close on click outside and/or escape

  closeIfOutside(event) {
    if (!(this.hasInsideTarget ? this.insideTarget : this.element).contains(event.target)) this.close(event);
  }

  closeOnEscape(event) {
    if (event.code == 'Escape') this.close(event);
  }

  // Open on hover (optional)

  openOnHover(event) {
    this.clearHoverTimeouts();
    this.openOnHoverTimeout = window.setTimeout(() => {
      this.open(event);
    }, 150);
  }

  closeOnHover(event) {
    this.clearHoverTimeouts();
    this.closeOnHoverTimeout = window.setTimeout(() => {
      this.close(event);
    }, 150);
  }

  clearHoverTimeouts() {
    window.clearTimeout(this.openOnHoverTimeout);
    window.clearTimeout(this.closeOnHoverTimeout);
  }

  // Actions

  open(event) {
    if (this.isOpen) return;

    if (event) {
      if (this.handled(event)) return;
      if (this.enableHover && event.type == 'click') return;

      // Don't open links if already opening openable
      // Enables menu item double tap for tablet: first tap opens openable, second tap opens link
      event.preventDefault();
    }

    if (this.hasOpenClass) this.element.classList.add(this.openClass);
    this.isOpen = true;

    this.hideTargets.forEach((element) => (element.style.display = 'none'));
    this.showTargets.forEach((element) => (element.style.display = ''));
    this.focusTargets.forEach((element) => element.focus());

    this.disableScroll();

    if (this.hasDialogTarget) {
      this.dialogTarget.showModal();
    }
  }

  close(event) {
    if (this.handled(event) || !this.isOpen) return;
    if (this.enableHover && event.type == 'click') return;

    if (this.hasOpenClass) this.element.classList.remove(this.openClass);
    this.isOpen = false;

    this.hideTargets.forEach((element) => (element.style.display = ''));
    this.showTargets.forEach((element) => (element.style.display = 'none'));
    this.focusTargets.forEach((element) => element.blur());

    this.enableScroll();

    if (this.hasDialogTarget) {
      this.dialogTarget.close();
    }
  }

  toggle(event) {
    this.isOpen ? this.close(event) : this.open(event);
  }

  // State management helpers

  hideAndShowTarget() {
    this.hideTargets.forEach((element) => (element.style.display = this.isOpen ? 'none' : ''));
    this.showTargets.forEach((element) => (element.style.display = this.isOpen ? '' : 'none'));
  }

  toggleOpenClass() {
    if (!this.hasOpenClass) return;

    if (this.isOpen) this.element.classList.add(this.openClass);
    else this.element.classList.remove(this.openClass);
  }

  disableScroll() {
    if (!this.manageScrollValue) return;

    const scrollY = window.scrollY;

    document.body.style.position = 'fixed';
    if (document.body.offsetHeight > window.innerHeight) document.body.style.overflowY = 'scroll'; // Prevent scrollbar collapse
    document.body.style.width = '100%'; // Prevent Safari from collapsing body size
    document.body.style.top = `-${scrollY}px`;
  }

  enableScroll() {
    if (!this.manageScrollValue) return;

    const scrollY = document.body.style.top;

    document.body.style.position = 'relative';
    document.body.style.overflowY = 'auto';
    document.body.style.width = 'auto';
    document.body.style.top = '';

    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  // Never handle an event more than once for a single controller
  // Example: A click on an open button is also a click outside
  handled(event) {
    event.handledForOpenableControllers ||= [];

    if (event.handledForOpenableControllers.includes(this)) {
      return true;
    } else {
      event.handledForOpenableControllers.push(this);
      return false;
    }
  }
}
