import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'template'];
  static values = {
    open: { type: Boolean, default: false },
    serializeForm: { type: Boolean, default: true },
  };

  get isRemote() {
    return this.data.has('url');
  }

  get portal() {
    return document.getElementById('portal');
  }

  connect() {
    if (this.openValue) {
      this.openModal();
    }

    this.element.addEventListener('click', this.handleClick);
    this.buttonTargets.forEach((b) => b.addEventListener('click', this.handleButtonClick));
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick);
    this.buttonTargets.forEach((b) => b.removeEventListener('click', this.handleButtonClick));
  }

  handleClick = (e) => {
    e.preventDefault();
  };

  handleButtonClick = () => {
    this.openModal();
  };

  openModal() {
    this[this.isRemote ? 'openRemoteModal' : 'openInlineModal']();
  }

  openInlineModal() {
    const modal = this.templateTarget.content.cloneNode(true);
    this.addModal(modal);
    this.updateUrl();
  }

  formData() {
    if (!this.serializeFormValue || !this.buttonTarget?.form) {
      return new FormData();
    }

    const formData = new FormData(this.buttonTarget.form);
    formData.append(this.buttonTarget.name, this.buttonTarget.value);

    return formData;
  }

  openRemoteModal() {
    // The inert attribute completely disables interaction with the link element.
    $(this.element).prop('inert', true);
    $.rails.disableFormElement($(this.buttonTarget));

    const handleAlways = () => {
      $(this.element).prop('inert', false);

      $.rails.enableFormElement($(this.buttonTarget));
    };

    const method = this.data.get('method')?.toUpperCase() || 'PUT';
    let url = new URL(this.data.get('url'), document.location);
    let body = this.formData();

    if (method == 'GET') {
      for (var pair of body.entries()) {
        url.searchParams.append(pair[0], pair[1]);
      }

      body = null;
    }

    fetch(url, {
      body: body,
      credentials: 'same-origin',
      headers: {
        Accept: 'text/html',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
      },
      method: method,
    })
      .then((response) => response.text())
      .then((response) => {
        // Remove filename annotations added in development mode.
        response = response.replace(/\x3C!-- BEGIN .+ -->\n/g, '');
        response = response.replace(/\x3C!-- END .+ -->\n/g, '');
        this.addModal(response);
        this.updateUrl();
      })
      .then(handleAlways, handleAlways);
  }

  addModal(modal) {
    $(modal).appendTo(this.portal);
    const $portalModal = $(this.portal).children().last();
    $portalModal.find('[autofocus]').trigger('focus');
  }

  updateUrl() {
    const id = this.element.id;

    if (!id) return;

    const url = new URL(window.location.href);
    url.searchParams.set('modal_id', id);
    history.replaceState({}, '', url);
  }
}
