import ToggleByController from './toggle_by_controller';

/**
 * Shows an element based on the state of a checkbox, radio, or select input.
 * @example
 * %input(value="value1" type="radio" name="my-radio-input") Radio 1
 * %input(value="value2" type="radio" name="my-radio-input") Radio 2
 * %input(value="value3" type="radio" name="my-radio-input") Radio 3
 *
 * %div(data-controller="show-by" data-show-by-radio="[name=my-radio-input]" data-show-by-value="value1") Shown when `Value 1` is checked
 * %div(data-controller="show-by" data-show-by-radio="[name=my-radio-input]" data-show-by-values="value1,value2") Shown when `Value 1` or `Value 2` is checked
 *
 * @example
 * %input(type="checkbox" name="my-checkbox-input") Checkbox
 * %div(data-controller="show-by" data-show-by-checkbox="[name=my-checkbox-input]") Shown when checkbox is checked
 *
 * @example
 * %select(id="my_select")
 *   %option(value="value1") Value 1
 *   %option(value="value2") Value 2
 *
 * %div(data-controller="show-by" data-show-by-select="#my_select" data-show-by-value="value1") Shown when `Value 1` is selected
 */
export default class extends ToggleByController {
  toggle = (isToggled) => {
    $(this.element).toggle(isToggled);
  };
}
