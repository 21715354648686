import { Controller } from '@hotwired/stimulus';

// This controller can be removed once all Nucleus components are converted to Stimulus controllers.
// For now it can be used to initalize Nucleus components that are added to the DOM after page load.
// For example when using DS modals.
export default class extends Controller {
  connect() {
    $(this.element).initialize();
  }
}
