import { Controller } from '@hotwired/stimulus';

/**
 * Abstract controller to toggle behavior depending on the state of a checkbox or radio input.
 * See show_by_controller.js and enable_by_controller.js for usage examples.
 */
export default class extends Controller {
  static outlets = ['suggest'];

  get event() {
    return this.data.get('event') || 'change';
  }

  connect() {
    this.value = this.data.get('value');
    this.values = !!this.data.get('values') ? this.data.get('values').split(',') : [];

    this.$checkboxTarget = $(this.data.get('checkbox'));
    this.$radioTarget = $(this.data.get('radio'));
    this.$selectTarget = $(this.data.get('select'));
    this.$inputTarget = $(this.data.get('input'));

    this.$checkboxTarget.on(`${this.event} checkboxChange.selectAll`, this.handleCheckboxChange);
    this.$radioTarget.on(this.event, this.handleRadioChange);
    this.$selectTarget.on(this.event, this.handleSelectChange);
    this.$inputTarget.on(this.event, this.handleInputChange);

    // Call event handlers once per target to set the correct initial state
    this.$checkboxTarget.length && this.handleCheckboxChange();
    this.$radioTarget.length && this.handleRadioChange();
    this.$selectTarget.length && this.handleSelectChange();
    this.$inputTarget.length && this.handleInputChange();
  }

  disconnect() {
    this.$checkboxTarget.off(`${this.event} checkboxChange.selectAll`, this.handleCheckboxChange);
    this.$radioTarget.off(this.event, this.handleRadioChange);
    this.$selectTarget.off(this.event, this.handleSelectChange);
    this.$inputTarget.off(this.event, this.handleInputChange);
  }

  handleCheckboxChange = () => {
    const any = this.data.has('any');
    const isToggled = this.$checkboxTarget.toArray()[any ? 'some' : 'every']((elem) => elem.checked);

    this.toggle(isToggled);
  };

  handleRadioChange = () => {
    // Retrieve value of the radio button that is checked
    this.toggle(this.matchesValue(this.$radioTarget.filter(':checked').val()));
  };

  handleSelectChange = () => {
    this.toggle(this.matchesValue(this.$selectTarget.val().toString()));
  };

  handleInputChange = () => {
    this.toggle(this.matchesValue(this.$inputTarget.val()));
  };

  handleDatepickerChange = () => {
    this.toggle(this.matchesValue(this.datepickerTarget.value));
  };

  suggestOutletConnected(suggestController) {
    this.handleSuggestValueChange(suggestController.defaultValue);
    suggestController.element.addEventListener('change', this.handleSuggestChange);
  }

  suggestOutletDisconnected(suggestController) {
    suggestController.element.removeEventListener('change', this.handleSuggestChange);
  }

  handleSuggestChange = (event) => {
    this.handleSuggestValueChange(event.detail.value);
  };

  handleSuggestValueChange(value) {
    // Use `some` instead of `every` so that by default it works as expected for multi selects
    // If no values are selected it will only toggle if data-<x>-by_empty is present
    this.toggle(value.length ? value.some((choice) => this.matchesValue(choice.value)) : this.data.has('empty'));
  }

  matchesValue = (targetValue) => {
    return this.value === targetValue || this.values.includes(targetValue);
  };

  toggle = (isToggled) => {
    // Placeholder implementation, can be overriden in subclass (see show_by_controller.js and enable_by_controller.js)
  };
}
