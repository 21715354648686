import { Controller } from '@hotwired/stimulus';
import React from 'react';
import ReactDOM from 'react-dom';
import PeriodSelector from '../react_components/period_selector.jsx';

export default class extends Controller {
  static values = { props: Object };
  static targets = ['startDateField', 'endDateField', 'component'];

  async connect() {
    ReactDOM.render(
      <PeriodSelector
        {...this.propsValue}
        onSelect={this.handleSelect}
        onReset={this.handleReset}
        onUpdateDropdownPosition={this.handleUpdateDropdownPosition}
      />,
      this.componentTarget,
    );
  }

  disconnect() {
    ReactDOM.unmountComponentAtNode(this.componentTarget);
    delete this.component;
  }

  handleReset = () => {
    $(this.startDateFieldTarget).val('');
    $(this.endDateFieldTarget).val('');
    this.element.dispatchEvent(new Event('period-select:change', { bubbles: true }));
  };

  handleSelect = (startDate, endDate) => {
    $(this.startDateFieldTarget).val(startDate.format('YYYY-MM-DD'));
    $(this.endDateFieldTarget).val(endDate.format('YYYY-MM-DD'));
    this.element.dispatchEvent(new Event('period-select:change', { bubbles: true }));
  };

  handleUpdateDropdownPosition = () => {
    const $dropdown = $(this.element).find('.enhanced-dropdown');
    const $select = $(this.element).find('.enhanced-dropdown-select');

    if ($dropdown.offset() == undefined) return;

    const spaceToWindowTop = $dropdown.offsetWindowTop();
    let spaceToDocumentTop = $dropdown.offset().top;

    const $offset = $($(this.element).data('offset'));

    if ($offset.length) spaceToDocumentTop -= $offset.outerHeight(true);
    if ($offset.length) spaceToDocumentTop -= $offset.offset().top;

    const selectHeight = $select.outerHeight();

    if (spaceToWindowTop > selectHeight && spaceToDocumentTop > selectHeight) {
      $select.css({ bottom: $dropdown.outerHeight() });
    } else {
      $select.css({ bottom: 'auto' });
    }
  };
}
