import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['slide', 'indicator'];
  static classes = ['active'];

  // Lifecycle setup

  connect() {
    this.activate(0);
    this.startRotation();

    this.boundHandleNextPreviousKeys = this.handleNextPreviousKeys.bind(this);
    document.addEventListener('keydown', this.boundHandleNextPreviousKeys);

    this.boundHandleNextPreviousFlick = this.handleNextPreviousFlick.bind(this);
    document.addEventListener('flick', this.boundHandleNextPreviousFlick);

    this.boundStopRotation = this.stopRotation.bind(this);
    this.element.addEventListener('mouseenter', this.boundStopRotation);

    this.boundStartRotation = this.startRotation.bind(this);
    this.element.addEventListener('mouseleave', this.boundStartRotation);
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundHandleNextPreviousKeys);
    document.removeEventListener('flick', this.boundHandleNextPreviousFlick);

    this.element.removeEventListener('mouseenter', this.boundStopRotation);
    this.element.removeEventListener('mouseleave', this.boundStartRotation);
  }

  // Lifecycle functions

  handleNextPreviousKeys(event) {
    if (event.code == 'ArrowRight') this.next();
    if (event.code == 'ArrowLeft') this.previous();
  }

  handleNextPreviousFlick(event) {
    if (event.direction == -1) this.next();
    if (event.direction == 1) this.previous();
  }

  startRotation() {
    this.rotationTimeout = window.setTimeout(() => {
      this.next();
      this.startRotation();
    }, 5000);
  }

  stopRotation() {
    window.clearTimeout(this.rotationTimeout);
  }

  // Activation

  previous() {
    this.activate(this.index - 1);
  }

  next() {
    this.activate(this.index + 1);
  }

  activate(index) {
    // Wrap around (using JS remainder (%) to create a modulo)
    index = ((index % this.slideTargets.length) + this.slideTargets.length) % this.slideTargets.length;

    // Keep track of current and previous indices
    this.previousIndex = typeof this.index == 'undefined' ? index : this.index;
    this.index = index;

    // Set indicator, i.e. 1/4
    if (this.hasIndicatorTarget) {
      this.indicatorTarget.innerHTML = `${index + 1}/${this.slideTargets.length}`;
    }

    // Manage active class and z-index
    this.slideTargets.forEach((slide, index) => {
      if (index == this.index) {
        // Fade in current slide if it changed
        if (this.previousIndex != this.index) {
          var fadeInStart = new Date();
          var fadeInStep = () => {
            slide.style.opacity = (new Date() - fadeInStart) / 200; // Fade in over 200ms
            if (slide.style.opacity <= 1) window.requestAnimationFrame(fadeInStep);
            else slide.style.opacity = null;
          };
          fadeInStep();
        }

        // Current slide gets z-index 2
        slide.style.zIndex = 2;
      } else {
        // Previous slide gets z-index 1, other slides get z-index 0
        slide.style.zIndex = index == this.previousIndex ? 1 : 0;
      }
    });
  }
}
