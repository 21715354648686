import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static classes = ['overlapped'];
  static targets = ['subject'];

  // Lifecycle

  connect() {
    this.boundSetClassIfOverlapping = this.setClassIfOverlapping.bind(this);
    window.addEventListener('resize', this.boundSetClassIfOverlapping);
    window.addEventListener('toggle-stuck', this.boundSetClassIfOverlapping);

    this.setClassIfOverlapping();
  }

  disconnect() {
    window.removeEventListener('resize', this.boundSetClassIfOverlapping);
    window.removeEventListener('toggle-stuck', this.boundSetClassIfOverlapping);
  }

  setClassIfOverlapping() {
    // Remove class first so we can check if it fits after resize
    this.element.classList.remove(this.overlappedClass);

    if (this.someSubjectsOverlap()) this.element.classList.add(this.overlappedClass);
  }

  // Helper methods to check whether subjects overlap

  someSubjectsOverlap() {
    return this.subjectTargets.some((subject, index) => {
      return this.subjectTargets.slice(index + 1).some((otherSubject) => {
        return this.rectsOverlap(subject.getBoundingClientRect(), otherSubject.getBoundingClientRect());
      });
    });
  }

  rectsOverlap(rect, otherRect) {
    return !(rect.right < otherRect.left || rect.left > otherRect.right || rect.bottom < otherRect.top || rect.top > otherRect.bottom);
  }
}
