import React from 'react';

export default function PeriodSelectorDay({
  startDate,
  endDate,
  hoverDate,
  mode,
  holidays,
  day,
  monthDate,
  minDate,
  maxDate,
  maxDuration,
  onSelectStartDate,
  onSelectEndDate,
  onHoverDate,
}) {
  const sameMonth = day.isSame(monthDate, 'month');
  const isAfterStart = startDate && day.isAfter(startDate);
  const isAfterMinDate = minDate ? day.isSameOrAfter(minDate) : true;
  const isBeforeMaxDate =
    maxDate && day.isSameOrBefore(maxDate) && (!maxDuration || mode !== 'select_end' || day.diff(startDate, 'days') <= maxDuration);

  function handleMouseEnter() {
    if (isAfterStart) onHoverDate(day);
  }

  function handleClick() {
    if (isAfterMinDate && isBeforeMaxDate) {
      if (isAfterStart) {
        if (mode === 'select_start') {
          onSelectStartDate(day);
          onHoverDate(null);
        } else {
          onSelectEndDate(day);
          onHoverDate(null);
        }
      } else {
        onSelectStartDate(day);
        onHoverDate(null);
      }
    }
  }

  return (
    <td
      title={day.date()}
      className={dayClass({ sameMonth, isAfterMinDate, isBeforeMaxDate, startDate, endDate, hoverDate, isAfterStart, day })}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
    >
      {sameMonth &&
        (isAfterMinDate && isBeforeMaxDate ? (
          <>
            <a role="button" aria-label={day.format('LL')}>
              {day.date()}
            </a>
            {maybeRenderHolidayBar({ holidays, day })}
          </>
        ) : (
          day.date()
        ))}
    </td>
  );
}

function maybeRenderHolidayBar({ holidays, day }) {
  if (holidays.some((holiday) => day.isSame(holiday))) {
    return <HolidayBar />;
  } else {
    return null;
  }
}

function HolidayBar() {
  return (
    <div className="calendar-day-bar clearfix">
      <div className="calendar-day-bar-section calendar-day-holiday col-xs-12 p-0"></div>
    </div>
  );
}

function dayClass({ sameMonth, isAfterMinDate, isBeforeMaxDate, startDate, endDate, hoverDate, isAfterStart, day }) {
  let dayClass = '';

  if (!sameMonth) {
    dayClass = 'calendar-placeholder';
  } else if (isAfterMinDate && isBeforeMaxDate) {
    let stateClass = '';

    if (startDate) {
      if (day.isSame(startDate, 'day')) {
        stateClass = 'calendar-selected-start';
      } else if (endDate) {
        if (day.isSame(endDate, 'day')) {
          stateClass = 'calendar-selected-end';
        } else if (day.isBetween(startDate, endDate)) {
          stateClass = 'calendar-selected';
        }
      } else if (hoverDate) {
        if (isAfterStart) {
          if (day.isSame(hoverDate, 'day')) {
            stateClass = 'calendar-selected-end';
          } else if (day.isBefore(hoverDate)) {
            stateClass = 'calendar-selected';
          }
        }
      }
    }

    dayClass = 'calendar-available' + (stateClass ? ' ' + stateClass : '');
  } else {
    dayClass = 'calendar-too-early';
  }

  return dayClass;
}
